// Generated by Framer (19eed98)

import { addFonts, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["jNpylsJgu"];

const serializationHash = "framer-GOaGn"

const variantClassNames = {jNpylsJgu: "framer-v-1dvfh0l"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "jNpylsJgu", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-GOaGn", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1dvfh0l", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"jNpylsJgu"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-cd708a3c-25bb-4606-92f4-351c74f13945, rgb(0, 0, 0))", ...style}}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-GOaGn [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-GOaGn .framer-15qj7hb { display: block; }", ".framer-GOaGn .framer-1dvfh0l { height: 1658px; overflow: hidden; position: relative; width: 400px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1658
 * @framerIntrinsicWidth 400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramerU9ifNC1AN: React.ComponentType<Props> = withCSS(Component, css, "framer-GOaGn") as typeof Component;
export default FramerU9ifNC1AN;

FramerU9ifNC1AN.displayName = "bar";

FramerU9ifNC1AN.defaultProps = {height: 1658, width: 400};

addFonts(FramerU9ifNC1AN, [])